<template>
  <div class="page app">
    <div>
      <Header/>
      <div class="page__wrapper">
        <Aside/>
        <v-app>
          <router-view></router-view>
        </v-app>
      </div>
    </div>
  </div>
</template>

<script>

import Aside from '../components/Aside'
import Header from '../components/Header'
export default {
  name: 'VuetifyContainer',
    components: {
      Header,
      Aside
    },
    mounted() {
        menu.init()
        menuMobile.init()
        aside.init()
        moduleDropdown.init()
    }
}
</script>

<style lang="scss" scoped>
.theme--light.v-application {
  background: unset;
}
</style>